// index.js
import { createI18n } from "vue-i18n";
import zh from "./zh.js";
import en from "./en.js";

const navLang = navigator.language || navigator.userLanguage;
let langStorage = localStorage.getItem("lang");
let localLang = navLang;
let lang = localLang || langStorage || "en";
localStorage.setItem("lang", localLang);

const i18n = createI18n({
  locale: lang,
  fallbackLocale: "en",
  legacy: false,
  messages: {
    zh,
    en,
  },
});

export default i18n;
