import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "videoToMp3"
};
const _hoisted_2 = {
  class: "chooseLang"
};
const _hoisted_3 = {
  class: "el-dropdown-link"
};
const _hoisted_4 = {
  class: "titleBox"
};
const _hoisted_5 = {
  class: "btnBox"
};
const _hoisted_6 = {
  class: "fileBox"
};
const _hoisted_7 = {
  class: "el-upload__text"
};
const _hoisted_8 = {
  class: "tipsBox"
};
import { ref, reactive, computed } from "vue";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { ElMessage } from "element-plus";
import { useI18n } from 'vue-i18n';
export default {
  __name: 'index',
  setup(__props) {
    const {
      locale,
      t
    } = useI18n();
    // 选择语言(使用计算属性才能下拉实时变化)
    let languagesOptions = computed(() => {
      return [{
        label: t('videoToAudio.languageSetting.options.english'),
        value: 'en'
      }, {
        label: t('videoToAudio.languageSetting.options.chinese'),
        value: 'zh'
      }];
    });

    // 选择的文件
    const fileData = ref({});
    // 是否开启自动上传文件
    const autoUpload = ref(false);
    // 允许上传的最大文件
    const limit = ref(1);
    // 当前选择的文件列表
    const fileList = reactive([]);
    // ffmpeg相关数据
    const ffmpegDataALl = ref({
      loading: false,
      loadingText: `` // ffmpeg加载中
    });
    // ffmpeg的实例
    const ffmpegItem = ref(null);
    // 音频下载地址
    const audioUrl = ref(``);
    // 下拉菜单
    const selectOptions = ref([{
      type: `mp3`,
      command: `ffmpeg -i input.mp4 -vn -ar 44100 -ac 2 -b:a 192k output.mp3`,
      commandArr: [`-vn`, `-ar`, `44100`, `-ac`, `2`, `-b:a`, `192k`]
    }, {
      type: `wav`,
      command: `ffmpeg -i input.mp4 -vn -ac 2 output.wav`,
      commandArr: [`-vn`, `-ac`, `2`]
    }, {
      type: `aac`,
      command: `ffmpeg -i input.mp4 -vn -b:a 192k output.aac`,
      commandArr: [`-vn`, `-b:a`, `192k`]
    }, {
      type: `ogg`,
      command: `ffmpeg -i input.mp4 -vn -acodec libvorbis -b:a 192k output.ogg`,
      commandArr: [`-vn`, `-acodec`, `libvorbis`, `-b:a`, `192k`]
    }, {
      type: `flac`,
      command: `ffmpeg -i input.mp4 -vn output.flac`,
      commandArr: [`-vn`]
    }, {
      type: `m4a`,
      command: `ffmpeg -i input.mp4 -vn -b:a 192k output.m4a`,
      commandArr: [`-vn`, `-b:a`, `192k`]
    }]);
    // 默认类型
    const typeName = ref(`mp3`);
    // 转换的指令
    const audioCommandArr = ref([]);
    // 当前选择的语言
    const langVal = ref(``);
    // 转换的进度
    const progressNum = ref(0);
    // 格式化
    const format = percentage => percentage === 100 ? 'Full' : `${percentage}%`;
    // 监听文件上传前
    const beforeUploadFunc = files => {
      // console.log(`上传之前`, files)
      fileData.value = files;
      fileList.value = [files];
      progressNum.value = 0;
      // console.log(`fileList.value`, fileList.value)
      return false;
    };
    // 监听文件改变时
    const onChangeFilesFunc = files => {
      // console.log(`文件改变`, files)
    };
    // 文件超出限制时
    const onExceedFilesFunc = files => {
      // console.log(`文件超出限制时`, files)
    };
    // 点击开始转换
    const startTransFunc = () => {
      console.log(`点击开始转换`);
      if (!fileData.value.name) {
        ElMessage.error(t('videoToAudio.msgTips.emptyFile'));
        return false;
      }
      initFFmpegFunc();
    };
    // 点击下载音频
    const downloadFileFunc = () => {
      console.log(`点击下载音频`);
      // 根据 blob生成 url链接
      const objectURL = audioUrl.value;
      // 创建一个 a 标签Tag
      const aTag = document.createElement("a");
      // 设置文件的下载地址
      aTag.href = objectURL;
      // 设置保存后的文件名称
      aTag.download = fileData.value.outputFileName;
      // 给 a 标签添加点击事件
      aTag.click();
      // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。
      // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
      // URL.revokeObjectURL(objectURL);
      ElMessage({
        message: t('videoToAudio.msgTips.downloadSuccess'),
        type: "success"
      });
    };
    // 重置数据
    const resetDataFunc = () => {
      console.log(`重置数据`);
      audioUrl.value = ``;
      if (ffmpegItem.value && ffmpegItem.value.terminate) {
        ffmpegItem.value.terminate();
      }
      ffmpegItem.value = null;
      progressNum.value = 0;
    };
    // 实例化ffmpeg
    const initFFmpegFunc = async () => {
      // 先重置数据
      resetDataFunc();
      ffmpegDataALl.value.loading = true;
      console.log(`初始化FFmpeg`);
      // console.log(`FFmpeg`, FFmpeg);
      // 一定要用变量写,如果用that.ffmpeg.on就会报错找不到属性
      const ffmpeg = new FFmpeg();
      ffmpeg.on("log", e => {
        console.log(`log`, e.message);
      });
      ffmpeg.on("progress", ({
        progress,
        time
      }) => {
        let strHtml = `${progress * 100} % (transcoded time: ${time / 1000000} s)`;
        console.log(`strHtml`, strHtml);
        progressNum.value = parseFloat(progress * 100).toFixed(2);
      });
      ffmpegItem.value = null;
      ffmpegItem.value = ffmpeg;
      await ffmpegItem.value.load({
        coreURL: await toBlobURL(`/jsFiles/ffmpeg/umd/ffmpeg-core.js`, "text/javascript"),
        wasmURL: await toBlobURL(`/jsFiles/ffmpeg/umd/ffmpeg-core.wasm`, "application/wasm")
      }).then(async res => {
        console.log(`实例化完成`);
        await ffmpegItem.value.writeFile(fileData.value.name, await fetchFile(fileData.value));
        console.log(`写入视频数据完成`);
        let prefixName = fileData.value.name.split(".")[0];
        fileData.value.outputFileName = prefixName + `.` + typeName.value;
        console.log(`outputFileName`, fileData.value.outputFileName);
        await ffmpegItem.value.exec(["-i", fileData.value.name, ...audioCommandArr.value, fileData.value.outputFileName]);
        console.log(`转为MP3完成`);
        let audioData = await ffmpegItem.value.readFile(fileData.value.outputFileName);
        fileData.value.audioUrl = URL.createObjectURL(new Blob([audioData.buffer], {
          type: "audio/mp3"
        }));
        audioUrl.value = fileData.value.audioUrl;
        console.log(`转为bolb下载链接完成`, fileData.value.audioUrl);
      }).catch(err => {
        console.log(`实例化失败`, err);
      }).finally(() => {
        console.log(`ffmpeg finally`);
        ffmpegDataALl.value.loading = false;
      });
    };
    // 点击下拉菜单子项
    const handleClickSelectItem = (item = {}) => {
      console.log(`点击下拉菜单子项 click`, item);
      typeName.value = item.type;
      audioCommandArr.value = item.commandArr;
    };
    // 点击语言选项
    const selectItemHandleClick = (item = {}) => {
      langVal.value = item.value || `en`;
      console.log(`langVal`, langVal.value);
      locale.value = langVal.value;
      localStorage.setItem('lang', langVal.value);
    };
    return (_ctx, _cache) => {
      const _component_arrow_down = _resolveComponent("arrow-down");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_progress = _resolveComponent("el-progress");
      const _component_el_button = _resolveComponent("el-button");
      const _component_upload_filled = _resolveComponent("upload-filled");
      const _component_Files = _resolveComponent("Files");
      const _component_el_upload = _resolveComponent("el-upload");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(languagesOptions), (item, index) => {
            return _openBlock(), _createBlock(_component_el_dropdown_item, {
              key: index,
              onClick: $event => selectItemHandleClick(item),
              style: _normalizeStyle({
                color: item.value == langVal.value ? `rgb(121.3,187.1,255)` : `#212529`
              })
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["onClick", "style"]);
          }), 128))]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.$t('videoToAudio.languageSetting.title')) + " ", 1), _createVNode(_component_el_icon, {
          class: "el-icon--right"
        }, {
          default: _withCtx(() => [_createVNode(_component_arrow_down)]),
          _: 1
        })])]),
        _: 1
      })]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('videoToAudio.title')), 1), fileData.value.name ? (_openBlock(), _createBlock(_component_el_progress, {
        key: 0,
        percentage: progressNum.value,
        format: format,
        style: {
          "flex": "1",
          "padding": "0 20px",
          "box-sizing": "border-box"
        }
      }, null, 8, ["percentage"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectOptions.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_dropdown_item, {
              key: item.type,
              onClick: $event => handleClickSelectItem(item)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.type), 1)]),
              _: 2
            }, 1032, ["onClick"]);
          }), 128))]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('videoToAudio.typeTitle')) + " " + _toDisplayString(typeName.value), 1), _createVNode(_component_el_icon, {
            class: "el-icon--right"
          }, {
            default: _withCtx(() => [_createVNode(_component_arrow_down)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        type: "primary",
        onClick: startTransFunc,
        class: "transBtn"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('videoToAudio.transBtnText')), 1)]),
        _: 1
      })), [[_directive_loading, ffmpegDataALl.value.loading]]), audioUrl.value ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        type: "success",
        onClick: downloadFileFunc
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('videoToAudio.downloadBtnText')), 1)]),
        _: 1
      })) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_upload, {
        class: "upload-demo",
        drag: "",
        "show-file-list": true,
        "before-upload": beforeUploadFunc,
        onAutoUpload: autoUpload.value,
        limit: limit.value,
        onOnChange: onChangeFilesFunc,
        onOnExceed: onExceedFilesFunc,
        "file-list": fileList,
        "onUpdate:fileList": _cache[0] || (_cache[0] = $event => fileList = $event)
      }, {
        tip: _withCtx(() => [_createElementVNode("div", _hoisted_8, [fileData.value.name ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0,
          class: "fileIcon"
        }, {
          default: _withCtx(() => [_createVNode(_component_Files)]),
          _: 1
        })) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(fileData.value.name), 1)])]),
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          class: "el-icon--upload"
        }, {
          default: _withCtx(() => [_createVNode(_component_upload_filled)]),
          _: 1
        }), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('videoToAudio.uploadTips')), 1)]),
        _: 1
      }, 8, ["onAutoUpload", "limit", "file-list"])])]);
    };
  }
};